<template>
  <div>
    <div class="content">
      <div class="all">
      <div class="SearchBar">
        <el-form :inline="true" class="searchBoxForm">
          <el-form-item label="所属类型" class="searchItem">
            <!-- <el-select v-model="pageParams.userType" placeholder="请选择" size="mini" clearable @change="searchList()">
              <el-option
                v-for="item in options"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select> -->
            <el-cascader
                  size="mini"
                  multiple
                  style="width: 100%"
                  :options="DeviceTypeArray"
                  v-model="equipmentTypees"
                  :props="{ label: 'name', value: 'id' }"
                  separator=">"
                  clearable
                  collapse-tags
                  @change="deformation">
                </el-cascader>
          </el-form-item>
          <el-form-item label="使用情况" class="searchItem">
            <el-select v-model="pageParams.used" placeholder="请选择" size="mini" clearable @change="searchList()">
              <el-option
                v-for="item in useCondition"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="启用状态" class="searchItem">
            <el-select v-model="pageParams.generalStatus" placeholder="请选择" size="mini" clearable @change="searchList()">
              <el-option
                v-for="item in enableStart"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="模板名称" class="searchItem">
            <el-input
              size="mini"
              v-model="pageParams.tplName"
              placeholder="请输入"
              clearable
            ></el-input>
          </el-form-item>
          <el-form-item label="" class="searchItem">
             <el-button type="primary" @click="searchList()" size="mini" >查询</el-button>
             <el-button type="warning" @click="searchList(1)" size="mini" >重置</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="contain">
      <!-- <div class="table-height"> -->
      <div class="tableTool">
        <span > 
          <el-button type="text"
                style="
                  border: 1px solid #1082ff;
                  color: #1082ff;
                  padding: 0 14px;
                  height: 30px;
                " size="mini"
                v-if="$anthButtons.getButtonAuth('addTemplate')"
                 @click="goPath('/home/addSampleWalking')">添加</el-button>
        </span>
      </div>
      <el-table
            ref="tableData"
            :data="tableData"
            style="width: 100%;margin: 10px 0 15px;"
            border
            class="table-height-in"
            :stripe="true"
            height="calc(100vh - 380px)"
            :header-cell-style="{
              color: '#333',
              fontFamily: 'MicrosoftYaHeiUI',
              fontSize: '14px',
              fontWeight: 900,
              textAlign:'center',
              background: '#f8f8f9',
            }"
          >
        <el-table-column
          type="index"
          :index="indexMethod"
          label="序号"
          align="left"
          width="60"
        >
        </el-table-column>
        <el-table-column
            prop="tplName"
            label="模板名称"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
        <el-table-column
            prop="userTypeName"
            label="所属类型"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
        <el-table-column
            prop="itemsCount"
            label="采集项数量"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
        <el-table-column
            prop="used"
            label="使用情况"
            align="left"
            show-overflow-tooltip
          >
        </el-table-column>
        <el-table-column prop="generalStatus" label="启用状态" align="left">
          <template slot-scope="{row}">
              <el-tag v-if="row.generalStatus== 0" type="danger" size="mini">禁用</el-tag>
              <el-tag v-if="row.generalStatus== 1" type="success" size="mini">启用</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作" align="left" width="250">
          <template slot-scope="{row}">
            <div class="operateTool">
              <el-button type="primary" @click="goPath('/home/sampleWalkingDesc',row.id)"
              v-if="$anthButtons.getButtonAuth('lookTemplate')"
               size="mini">查看</el-button>
              <el-button type="primary" 
              v-if="row.used == '未使用' && row.generalStatus== 0 && $anthButtons.getButtonAuth('editTemplate')"  
              size="mini" @click="goPath('/home/addSampleWalking',row.id)">编辑</el-button>
              <el-button type="success" v-if="row.generalStatus== 0 && $anthButtons.getButtonAuth('onTemplate')" 
              @click="stateUpdata(row.id)" size="mini">启用</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
    <!-- </div> -->
      <el-pagination
        background
        layout="total, prev, pager, next,sizes, jumper"
        :total="total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="pageParams.current"
        :page-sizes="[10, 30, 50, 100]"
        :page-size="pageParams.size"
      >
      </el-pagination>
    </div>
    </div>
  </div>
</template>

<script>
import {
  templateGeneralStatusTogglePolling,
  templatePageListPolling
} from "@/RequestPort/dict/cycle";
import { patrolGetDict ,dicByType } from "../../RequestPort/maintenance";
import pageMixins from "@/utils/pageMixins";
export default {
  name:'Sample',
  mixins: [pageMixins],
  data() {
    return {
      pageParams: {
        current: 1,
        size: 100,
      }, //  分页查询参数
      tableData: [], //  表格数据
       total:0,
      title:'',
      dialogVisible:false,//启用弹框
      value:'',
      // 用户分类
      options:[],
      // 使用情况
      useCondition:[
        {label:'未使用',value:1},
        {label:'被使用',value:2},
      ],
      // 启用状态
      enableStart:[
        {label:'禁用',value:0},
        {label:'启用',value:1},
      ],
      DeviceTypeArray:[],
      equipmentTypees:[]
    };
  },
  mounted() {
    this.getList()
    // selectDictListByPid({pid: 460}).then(res => {
    //   this.options = res.data
    // })
    let that = this
    // patrolGetDict({pid:'111,112,113,114,720'}).then(res => {
    //     if(res.code == 200) {
    //       let tableData = res.data;
    //       tableData.forEach(el => {
    //         if(el.children && el.children.length > 0) {
    //           el.children.forEach(item => {
    //             that.options.push(item)
    //           })
    //         }
    //       })
    //     } else {
    //       this.$message.error(res.msg)
    //     }
    //   })
    this.DeviceTypeArray = []
    // 事件
      dicByType({parentCode:'GAS_PATROL_EVENT',level:2}).then(res=>{
          if(res.code===200){
            for(let i=0;i<res.data.length;i++){
            let el=res.data[i]
            if(!el.children){
              res.data.splice(i,1)
              i=i-1
            }
          }
          this.DeviceTypeArray= [...this.DeviceTypeArray,...res.data]
          console.log(this.DeviceTypeArray)
        }
      })
      // 设备
      dicByType({parentCode:'sblx',level:3}).then(res => {
        if(res.code == 200) {
            this.DeviceTypeArray= [...this.DeviceTypeArray,...res.data]
        } else {
            this.$message.error(res.msg)
        }
    }) 
  },
  methods: {
    deformation(){
      this.pageParams.userType = this.equipmentTypees[1]
      // this.getList()
    },
    /**
     * 获取列表
     */
    getList(){
      templatePageListPolling(this.pageParams).then(res => {
        this.tableData = res.data.records
        this.total = res.data.total
      })
    },
    /**
     * 更改状态
     */
    stateUpdata(val){
      let that = this
      this.$confirm('是否启用此模板，启用后将自动停用该类型其他模板', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          closeOnClickModal:false,
          type: 'warning'
        }).then(() => {
          templateGeneralStatusTogglePolling({id:val}).then(res => {
            that.$message({
              type: 'success',
              message: res.data
            });
            that.getList()
          })
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    /**
     * 搜索
     */
    searchList(val){
      
      if(val){
        this.equipmentTypees=[]
        this.pageParams ={
          current:1,
          size: 100,
        }
      } else {
        this.pageParams.current = 1
      }
      this.getList()
    },
    handleSizeChange(val) {
      this.pageParams.size = val
      this.pageParams.current = 1
      this.getList()
    },
    handleCurrentChange(val) {
      this.pageParams.current = val
      this.getList()
    },
    //跳转
    goPath(path, id, disable) {
      this.$router.push({ path: path, query: { id: id, disable: disable } })
    },
  },
};
</script>

<style lang="scss" scoped>
.searchBox {
  .searchBoxForm {
    display: flex;
    align-items: center;
  }
}
// .tableTool{    height: 25px;}
 .searchItem {
      margin-bottom: 1%;
      //  margin-left: 1%;
    }
// .info-item {
//   margin-bottom: 20px;
// }
.label {
  color: #909399;
}
.content-item {
  color: #444444;
}
.SearchBar {
  /* border: 1px solid #ecedf1; */
  border-radius: 5px;
  // margin: 10px;
  // padding-top: 1%;

  background-color: #ffffff;
}
.contain{
  margin:0;
  padding:0;
}

.all {
  // background-color:#f3f4f8;
  height:auto
}
.content {
  background: #ffffff;
  width: 100%;
  padding-top: 5px;
  // border-radius: 6px;
  overflow:auto;
  // height: calc(100vh - 245px);
}
.content-text {
  display: inline-block;
  width: 200px;
  vertical-align: middle;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.diaBtn{
  width: 100px;
  height: 40px;
  margin-bottom: 15px;
}
</style>